.fade-in {
  transition: opacity 1s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.LoginPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #053237;
}

.LayoutPageLoading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #053237;
}

.LogoBox {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #053237;
  cursor: default;
}

.LogoBox h1 {
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-weight: bold;
  color: #FFF;
}

.HeaderLayout {
  padding: 0;
  background: #FFF;
  display: flex;
  justify-content: end;
}

.TituloContenido {
  font-size: 2rem;
}

.FilterContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.FilterContainer .ant-form-item {
  margin-bottom: 0;
}

.RowContainer {
  display: flex;
  gap: 20px;
  align-items: start;
}

.ColumnContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ant-pagination-options {
  display: inline !important;
}

.ant-picker-time-panel {
  width: 150px !important;
}

.MonitoringList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
}

/* Estilos Mobile */
@media (max-width: 767px) {

  .RowContainer {
    flex-direction: column;
  }

  .LoginCard h1 {
    font-size: 20px;
    text-align: center;
  }

  .FilterContainer {
    flex-direction: column;
  }

  .ColumnContainer {
    font-size: 12px;
  }

}

/* Estilos Tablet */
@media (min-width: 768px) and (max-width: 1023px) {

  .FilterContainer {
    align-items: end;
  }

}

/* Estilos Desktop */
@media (min-width: 1024px) {

  .RowContainer {
    flex-direction: row;
    gap: 50px;
  }

  .LoginCard {
    width: 500px;
  }

  .LoginCard h1 {
    font-size: 30px;
    text-align: center;
  }

  .FilterContainer {
    flex-direction: row;
    gap: 20px;
    align-items: end;
  }

}

.calendar {
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.calendar-header select {
  padding: 5px;
  font-size: 14px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day,
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .calendar-grid {
    grid-template-columns: 1fr;
  }

  .day {
    height: auto;
    padding: 10px;
  }

  .BookingCardList {
    grid-template-columns: 1fr !important;
  }
}

.BookingCardList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}